<template>
  <h3 class="title-catalog">{{name}}</h3>
  <div class="row">
    <div
      class="col-lg-3"
      style="border-radius: 30px"
      v-for="book in books"
      v-bind:key="book"
    >
      <router-link :to="'/urun/' + book.id" style="text-decoration: none;">
        <Card :name="book.name" :img=" get_url+ '/assets/' + book.kapak + '?width=600&quality=80'"/>
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Card from "@/components/Card.vue";
import axios from "axios";

export default {
  name: "Cateogry",
  components: {
    Card,
  },

  data() {
    return {
      books: {},
      name : null,
    };
  },

  async mounted() {
    await this.getData();
    await this.getCategory();
  },

  computed:
  {
    get_url : function() {
      return process.env.VUE_APP_API_URL;
    }
  },

  methods: {
    getData() {
      axios
        .get(`${this.get_url}/items/Urunler`, {
          params: {
            filter: JSON.stringify({
              category: { Category_id: { _eq: this.$route.params.id } },
            }),
            sort: 'name'
          },
        })
        .then((response) => {
          this.books = response.data.data;
          console.log(response.data.data);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    getCategory() {
      axios
        .get(`${this.get_url}/items/Category/${this.$route.params.id}`)
        .then((response) => {
          this.name = response.data.data.name;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style>

.title-catalog {
  color: #000;
  font-family: Kurale;
  font-weight: 666;
}
</style>

