<template>

  <div class="row pb-5" v-if="product != null">

    <div class="col-lg">

      <carousel :items-to-show="1" ref="c">
        <slide v-for="im in productImages" :key="im" v-model="currentSlide">
          <img class="img" :src="get_url+ '/assets/' + im  + '?width=600&quality=80'"  style=" max-width: 100%">
        </slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>

    <div class="col-lg">
      <h2 class="font-weight-bold  title-catalog">
        {{product.name}}

      </h2>

      <div class="detail-text w-100" style="font-family:Kurale; text-align: justify">
        <div v-html="product.desc"></div>
      </div>

      <div class="options pt-5">
          
          <ul class="list-group list-custom" type="none">
              
              <li class="list-item py-2">
                  <h3 class="d-inline"> <i class="fa fa-child" aria-hidden="true"></i> {{product.age}} Yaş İçin Önerilir</h3>
                      
              </li>
              
              <li v-if="!product.sistem_urunu" class="list-item py-2">
                  <a :href="'http://www.multibemkitap.com/' + product.seo_link " target="_blank"><button class="btn btn-primary buy-button"><i class="fas fa-shop"></i><span>SATIN AL</span></button></a>
              </li> 
              <li v-else class="list-item py-2">
                  <h4 class="d-inline"> <i class="fa fa-star" aria-hidden="true"></i>Multibem Sistem Ürünüdür</h4>
              </li> 
          </ul>
      </div>
    </div>

  </div>

</template>

<script>
// @ is an alias to /src
import Card from "@/components/Card.vue";
import axios from 'axios';

  // Import Swiper styles
import 'font-awesome/css/font-awesome.css'

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  name: 'Cateogry',
  components: {
    Card,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      product: null,
      currentSlide: 1,
    };
  },

  async mounted() {
    await this.getData();
  },

  computed:
  {
    get_url : function() {
      return process.env.VUE_APP_API_URL;
    },
    productImages : function(){
      return [this.product.kapak.id, ...this.product.inner.map(x=> x.directus_files_id)];
    },
    getSlideIndex : function(){
      //debugger;
      return this.$refs.c.data.currentSlide._value;
    }
  },

  methods: {
    getData(){
      axios.get(`${this.get_url}/items/Urunler/${this.$route.params.id}?fields=*.*`,
      
      )
      .then(response => {
        this.product = response.data.data
        console.log(response.data.data)
      })
      .catch(e => {
        this.errors.push(e)
      })
    },
    getImageLink(image){

      return `${this.get_url}/assets/${image.id}?width=600&quality=80`;
    }
  }

}


</script>



<style>
.carousel__prev, .carousel__next{
  background-color: #0D4D66;
}

.buy-button {
  background-color: #2E86C7 !important;
  color: #fff;
  font-family: Kurale;
  width: 50%;
  height: 8vh;
}

.buy-button span {
  font-size: 1.5em;
  font-weight: bold;
}

.detail-text {
  font-family: Kurale;
  font-weight: bold;
  color: #0D4D66;
}

.title-catalog {
  color: #000;
  font-family: Kurale;
  font-weight: 666;
}

.list-custom {
  color: #005AA7;
  font-family: Kurale;
}

</style>