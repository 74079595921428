<template>
  <div class="home">
    
    <div class="row">
      <div class="col-lg-4" style="border-radius: 30px;" v-for="c in category" v-bind:key="c">
          <router-link :to="'/kategori/' + c.id" style="text-decoration: none;" >
          <CategoryCard :name="c.name" :img=" get_url + '/assets/' + c.cover  + '?width=900&quality=80'" />
          </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CategoryCard from "@/components/Card.vue";
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    CategoryCard
  },
  data() {
    return {
      category: {},
    };
  },

  async mounted() {
    await this.getData();
  },

  computed:
  {
    get_url : function() {
      return process.env.VUE_APP_API_URL;
    }
  },

  methods: {
    getData(){
       axios.get(`${this.get_url}/items/Category`)
      .then(response => {
        this.category = response.data.data
        console.log(response.data.data)
      })
      .catch(e => {
        this.errors.push(e)
      })
    }
  }

}
</script>
