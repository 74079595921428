<template>

    <div class="row py-3">

        <div class="col-lg-6 px-5 ">

            <div class="contact-item">
                <img class="contact-image" src="@/assets/img/harita-icon.png" />
                <span class="contact-text pl-2">Küçük Çamlıca Mah. Küçük Çamlıca Cad. No:39 Üsküdar / İstanbul</span>
            </div>
          
            <div class="contact-item">
                <img class="contact-image" src="@/assets/img/telefon-icon.png" />
                <span class="contact-text pl-2">0216 318 88 34</span>
            </div>
            
            <div class="contact-item py-4">
                <img class="contact-image" src="@/assets/img/web-icon.png" />
                <span class="contact-text pl-2">info@multibem.org</span>
            </div>

        </div>

        <div class="col-lg-6">
            <div class="card shadow ">
                <div class="card-body">
                    <div class="embed-responsive embed-responsive-1by1 shadow">
                        <iframe src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJcTzG7RPIyhQR5ijUYGYIyVY&key=AIzaSyDtV9Uo8zkfUKQzVXcmyHxUUl7MN9U_l6Y" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
  </div>

</template>

<script>

export default {
  name: 'Contact',
  components: {
  },

}
</script>


<style>

.contact-item {
  display: block;
  padding: 1rem 0rem 0px 0px;
  font-size: 1.1rem;
}
.contact-item .contact-text {
  font-family: Kurale;
}
.contact-item .contact-image {
  width: 3rem;
}

</style>

