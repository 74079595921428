<template>
  <div class="col" id="topbar"></div>

  <div class="container">

  
    <nav id="nav" class="navbar navbar-expand-lg" >
        

        <div class="flex-container">
          <a class="navbar-brand" href="/">
              <img style="width:30%" src="@/assets/img/logo.png"/>
            
          </a>

          <a class="navbar-toggler" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation" @click="toggleMenu" style="float: right">
              <span class="fa fa-bars"></span>
          </a>
        </div>

        <div class="collapse navbar-collapse" :class="{show: menuOpen}" id="navbarTogglerDemo01"> 
            
            <ul class="navbar-nav mt-2 mt-lg-0 w-100 mx-2" style="justify-content: end">
              <li class="nav-item">
                  <router-link class="nav-link" to="/">ANASAYFA</router-link>
              </li>
              <li class="nav-item">
                  <router-link class="nav-link" to="/katalog">KATALOG</router-link>
              </li>
              <li class="nav-item ">
                  <a class="nav-link" href="http://www.multibemkitap.com">KAMPANYALAR</a>
              </li>
              <li class="nav-item ">
                  <a class="nav-link" href="http://www.multibemkitap.com">MAĞAZA</a>
              </li>
              <li class="nav-item ">
                  <router-link class="nav-link" to="/yurtdisi">YURTDIŞI</router-link>
              </li>
              <li class="nav-item ">
                  <router-link class="nav-link" to="/iletisim">İLETİŞİM</router-link>
              </li>
            </ul>            
        </div>
    </nav>
    <hr style="width: 100%; margin: auto; border: none; border-top: 2px dashed grey;">
    <br>

    <router-view style="min-height: calc(100vh - 300px)"/>

    <br>
  </div>


    <footer class="container-fluid pt-5 footer">
    <div class="row">

        <div class="col-lg-12 col-md pl-5 py-3 ">
            <div class="row">
            <div class="col-lg text-center">
                <img src="@/assets/img/frame-1.png" width="100px" height="80px">
            </div>
            <div class="col-lg text-center">
                <img src="@/assets/img/frame-2.png" width="100px" height="80px">
            </div>


            <div class="col-lg text-center">
                <img src="@/assets/img/frame-3.png" width="100px" height="80px">
            </div>
            <div class="col-lg text-center">
                <img src="@/assets/img/frame-4.png" width="100px" height="80px">
            </div>
        </div>
        </div>

    </div>
        <div class="row ">
            
            <div class="col-lg-12 d-flex justify-content-center py-3">
                <a class="text-light pr-2" href="/">Ana Sayfa</a>
                <a class="text-light pr-2" href="/katalog">Katalog</a>
                <a class="text-light  pr-2" href="/kampanyalar">Kampanyalar</a>
                <a class="text-light pr-2" href="/iletisim">İletişim</a>

            </div>

        </div>
    <div class="col-lg-12 text-center lead">Multibem © 2020</div>
    <div class="footer-image"></div>

    </footer>

</template>


<script>
import { Collapse } from 'bootstrap';
import { defineComponent, onMounted, reactive, toRefs } from 'vue';

export default defineComponent({
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Multibem Yayınları',
    },
setup:() =>{
  const model = reactive({
    menuOpen: false,
  });

  onMounted(()=>{
 const collarseArr = [];
      var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'));
      var collapseList = collapseElementList.map(function (collapseEl) {
        collarseArr.push(new Collapse(collapseEl,{toggle:false}));
      });
  });

  const toggleMenu = () => model.menuOpen = !model.menuOpen;

  return {...toRefs(model), toggleMenu};
}
});


// export default {
//     name: 'Multibem Yayınları',
//     metaInfo: {
//       // if no subcomponents specify a metaInfo.title, this title will be used
//       title: 'Multibem Yayınları',
//     },
//     mounted(){

//       const collarseArr = [];
//       var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'));
//       var collapseList = collapseElementList.map(function (collapseEl) {
//         collarseArr.push(new Collapse(collapseEl));
//       });

//     },
// }
</script>

<style>

</style>

<style>

.footer {
  background-color: #065CA8;
  color: #fff;
  font-family: Kurale;
}
.footer .footer-image {
  min-height: 20vh;
  background-color: #065ca8;
  background-image: url("assets/img/footer-image.png");
  background-size: contain;
  background-repeat: repeat-x;
  background-position-y: bottom;
  background-position-x: center;
}

#topbar{
  height: 20px;
  background-color: #005AA7;
}

.container{
  max-width: 1140px !important;
}

.navbar {
  font-family: AustralSans;
}

.nav-link {
    color: black !important;
}

@media screen and (max-width: 1023px) {
  .fsize {
    font-size: 1.2rem;
  }

  .logo {
    width: 200px;
  }
}
@media (min-width: 1024px) and (max-width: 3000px) {
  .fsize {
    font-size: 0.9rem;
  }

  .logo {
    width: 350px;
  }
}

@font-face {
  font-family: Kurale;
  src: url("assets/fonts/Kurale-Regular.ttf") format("truetype");
}
@font-face {
  font-family: AustralSans;
  src: url("assets/fonts/AustralSansStamp.ttf") format("truetype");
}


</style>
