<template>
 
    <div class="card mb-4 shadow bdradius">
        <img class="card-img-top" :src="img" data-holder-rendered="true" style=" width: 100%; display: block;">
        <div class="card-body text-center cb-style bdradius shadow d-flex justify-content-between py-1 mt-2">

            <span></span>
            <p class="card-text lead font-weight-bold d-inline">{{name}}</p>
            <span class="card-text lead font-weight-bold"></span>

        </div>
    </div>

</template>

<script>
export default {
  name: 'Card',
  props: {
    name: String,
    img: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.bdradius {
  border-radius: 10px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.font-weight-bold{
  font-weight: 700;
}

.card {
  font-family: Kurale;
  color: #093347 !important;
}
</style>
