import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Category from '../views/Category.vue'
import Product from '../views/Product.vue'
import Contact from '../views/Contact.vue'
import Catalog from '../views/Catalog.vue'
import MPublishing from '../views/MPublishing.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/kategori/:id',
    name: 'Kategoris',
    component: Category
  },
  {
    path: '/urun/:id',
    name: 'Kategori',
    component: Product
  },
  {
    path: '/katalog',
    name: 'Katalog',
    component: Catalog
  },
  {
    path: '/yurtdisi',
    name: 'Yurtdışı Satış', 
    component: MPublishing
  },
  {
    path: '/iletisim',
    name: 'İletişim',
    component: Contact
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
