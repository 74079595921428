<template>

  <div class="row py-3">

    <div class="col-lg-6 px-5 ">

      <div class="contact-item">
        <span class="contact-text pl-2">Multibem kitaplarını yurtdışından sipariş vermek için lütfen bu formu
          doldurunuz veya 
          <!-- mailto -->
          <a href="mailto:siparis@multibem.com.tr"> siparis@multibem.com.tr </a> ile e-posta üzerinden iletişime geçiniz.</span>
      </div>

    </div>

    <div class="col-lg-6">
      <div class="card shadow ">
        <div class="card-body">
          <!-- Mail to form -->
          <form action="mailto:siparis@multibem.com.tr" method="GET" enctype="text/plain">

            <input name="subject" type="hidden" value="Yurtdışı Sipariş Formu">

            <label for="subject">Mesajınız</label>
            <br/>

            <textarea  name="body" class="w-100" placeholder="İsim Soyisim:&#10;Ülke:&#10;Sipariş Detayı:" style="height:200px"></textarea>
            
            <br />
            <input class="btn btn-secondary mt-2" type="submit" value="Gönder">

          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'MPublishing',
  components: {
  },

}
</script>


<style>
.contact-item {
  display: block;
  padding: 1rem 0rem 0px 0px;
  font-size: 1.1rem;
}

.contact-item .contact-text {
  font-family: Kurale;
}

.contact-item .contact-image {
  width: 3rem;
}
</style>

