<template>

  <h2 class="py-3">2022 Yayın Kataloğumuz</h2>
  <p>2022 Türkçe Yayın kataloğumuzu <a href="https://www.multibem.com.tr/wp-content/uploads/2022/01/turkce2022.pdf">buraya</a> tıklayarak indirebilirsiniz</p>
  <p>2022 İngilizce Yayın kataloğumuzu <a href="https://www.multibem.com.tr/wp-content/uploads/2022/01/ingilizce.pdf">buraya</a>  tıklayarak indirebilirsiniz. (You can download our catalog from here.)</p>

</template>

<script>

export default {
  name: 'Catalog',
  components: {
  },

}
</script>


<style>

.contact-item {
  display: block;
  padding: 1rem 0rem 0px 0px;
  font-size: 1.1rem;
}
body{
  font-family: Kurale;
}

h1, h2, h3{
  font-family: Kurale;
}

p{
  font-family: Kurale;
  color: #0d4d66;
}

.contact-item .contact-image {
  width: 3rem;
}

</style>

